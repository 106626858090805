import axios from "axios";
import _ from "lodash";

const { REACT_APP_API_URL } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) config.headers["Authorization"] = token;
  return config;
});


class API {

  async createProductTemplate (category, name, code, toast) {
    const url = "/create-product-template";
    const data = {
      category,
      name,
      code,
    };
    let result;
    try {
      result = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product Template: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }

    return result;
  };

  async createProduct (
    code,
    salable,
    template,
    listPrice,
    costPrice,
    variantName,
    weight,
    toast
  ) {
    const url = "/create-product";
    const data = {
      code,
      salable,
      template,
      listPrice,
      costPrice,
      variantName,
      weight,
    };
    let result;
    try {
      result = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getProductTemplate(name, toast) {
    const url = `/get-product-template?name=${name}`;
    let result;
    try {
      result = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Template: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getProductCategory(name, toast) {
    const url = `/get-product-category?name=${name}`;
    let result;
    try {
      result = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Category: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getProductSupplier(product, toast) {
    const url = `/get-product-supplier?product=${product}`;
    let result;
    try {
      result = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Supplier: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getPartyContact(name, toast) {
    const url = `/get-supplier-contact?name=${name}`;
    let result;
    try {
      result = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Party Contact: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async createPartyContact(name, toast) {
    const url = "/create-supplier-contact";
    const data = {
      name,
    };
    let result;
    try {
      result = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Party Contact: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async createProductSupplier(product, party, toast) {
    const url = "/create-product-supplier";
    const data = {
      product,
      party,
    };
    let result;
    try {
      result = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product Supplier: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async createProductCategory(name, toast) {
    const data = {
      name,
    };
    const url = "/create-product-category";
    let result;
    try {
      result = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product Category: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getProduct(code, toast) {
    const url = `/get-product?code=${code}`;
    let result;
    try {
      result = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async addAttributesToProduct({
    attribute,
    valueSelection: value_selection,
    product,
  }, toast) {
    const url = "/add-attribute-to-product";
    const data = {
      attribute,
      value_selection,
      product,
    };
    let response;
    try {
      response = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getProductAttribute({ attribute }, toast) {
    const url = `/get-product-attribute?name=${attribute.toLowerCase()}`;
    let response;
    try {
      response = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Supplier: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async createProductAttribute(attribute, toast) {
    const url = "/create-product-attribute";
    const data = {
      attribute,
    };
    let response;
    try {
      response = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product attribute: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return this.sleep(3000).then(() => response); //sleep for fulfil to update its information about attrubute created
  };

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getProductAttributeOption(attribute, name, toast) {
    const url = `/get-product-attribute-option?name=${encodeURIComponent(name)}&attribute=${parseInt(
      attribute
    )}`;
    let response;
    try {
      response = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Supplier: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getProductLinkedOptions(attributeId, productId, toast) {
    const url = `/product-linked-options?attributeId=${parseInt(attributeId)}&productId=${parseInt(productId)}`;
    let response;
    try {
      response = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product Linked options: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async deleteProductLinkedOption(id, toast) {
    const url = `/product-linked-options/${parseInt(id)}`;
    let result;
    try {
      result = await instance.delete(url);
      return result;
    } catch (err) {
      const errorMessage = `An exception has occurred while deleting the Product linked option: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
      return;
    }
  };
  async createAttributeOption(attribute, name, toast) {
    const url = "/create-product-attribute-option";
    const data = {
      attribute,
      name,
    };
    let response;
    try {
      response = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async updateProduct(id, data, toast) {
    const url = `/product/${id}`;
    let result;
    try {
      result = await instance.put(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while updating the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  };

  async getBarcode(id, toast) {
    const url = `/product/barcode/${id}`;
    let result;
    try {
      result = await instance.get(url);
      console.log("result: ", result);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
      return;
    }
    if (result.status == 200) return result.data.message;
    return;
  };

  async revalueInventory(productId, cost, toast) {
    const url = `/product/revalue-inventory/${productId}`;
    let result = false;
    try {
      result = await instance.put(url, {
        cost_price: cost
      });
    } catch (err) {
      const errorMessage = `An exception has occurred while revalueting the Product inventory: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return result;
  }

  async deleteBarcode(id, toast) {
    const url = `/product/barcode/${id}`;
    let result;
    try {
      result = await instance.delete(url);
      return result;
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
      return;
    }
  };

  async createBarcode(product, barcode, type, toast) {
    const url = "/product/barcode";
    const data = {
      product,
      barcode,
      type,
    };
    let response;
    try {
      response = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async validateBarcode(barcode, toast) {
    return new Promise(async (resolve, reject) => {
      console.log("Barcode: ", barcode);
      const url = "/product/barcode/validate" + "?barcode=" + barcode;
      console.log("URL: ", url);
      let result;
      try {
        result = await instance.get(url);
        console.log("RESULT: ", result);
      } catch (err) {
        const errorMessage = `An exception has occurred while validating the barcode: ${err}`;
        console.log(errorMessage);
        toast(errorMessage, {autoClose: false});
        reject();
      }
      if (!_.isEmpty(result.data.message)) {
        const { validity } = result.data.message;
        resolve(validity);
      }
    });
  };

  async getShopifyOperationStatus(bulkOperationId, toast) {
    const url = "/start-shopify-operation-status?bulkOperationId=" + bulkOperationId;
    let response;
    try {
      response = await instance.get(url);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the shopify operation status: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getShopifyProductsCreateResults(bulkOperationId, productsData, toast) {
    const url = "/get-shopify-products-create-results?bulkOperationId=" + bulkOperationId;
    let response;
    try {
      response = await instance.post(url, productsData);
    } catch (err) {
      const errorMessage = `An exception has occurred while getting the shopify operation status: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async startShopifyProductsCreate(productsData, toast) {
    const url = "/start-shopify-products-create";
    let response;
    try {
      response = await instance.post(url, productsData);
    } catch (err) {
      const errorMessage = `An exception has occurred while starting the shopify products create: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async createListings(shopifyProductsData, fulfilProductsData, toast) {
    const url = "/create-listings";
    let response;
    try {
      response = await instance.post(url, {shopifyProductsData, fulfilProductsData});
    } catch (err) {
      const errorMessage = `An exception has occurred while creating listings: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }

    return response;
  };

  async startImport(productsData, toast) {
    const url = "/start-import";
    let response;
    try {
      response = await instance.post(url, productsData);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating import: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }

    return response;
  };

  async restartImport(importSK, toast) {
    const url = "/restart-import";
    let response;
    try {
      response = await instance.post(url, {importSK});
    } catch (err) {
      const errorMessage = `An exception has occurred while restarting import: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }

    return response;
  };

  async getImports(rowsPerPage, fromKey, toast) {
    const url = "/get-imports";
    let response;
    try {
      response = await instance.get(url, {params: {rowsPerPage, fromKey}});
    } catch (err) {
      const errorMessage = `An exception has occurred while getting imports: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getProducts(importId, rowsPerPage, fromKey, toast) {
    const url = "/get-products";
    let response;
    try {
      response = await instance.get(url, {params: {rowsPerPage, fromKey, importId}});
    } catch (err) {
      const errorMessage = `An exception has occurred while getting products: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getLogs(logId, searchTerm, rowsPerPage, fromKey, toast) {
    const url = "/get-logs";
    let response;
    try {
      response = await instance.get(url, {params: {rowsPerPage, fromKey, logId, searchTerm}});
    } catch (err) {
      const errorMessage = `An exception has occurred while getting logs: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getUnits(toast) {
    let response;
    try {
      response = await instance.get('/get-units');
    } catch (err) {
      const errorMessage = `An exception has occurred while getting units: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async getProductBrands(brandName,  toast) {
    let response;
    try {
      response = await instance.get('/get-product-brands');
    } catch (err) {
      const errorMessage = `An exception has occurred while getting product brands: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response;
  };

  async createProductBrand(brandName, toast) {
    const url = "/create-product-brand";
    const data = {
      brandName,
    };
    let response;
    try {
      response = await instance.post(url, data);
    } catch (err) {
      const errorMessage = `An exception has occurred while creating the Product brand: ${err}`;
      console.log(errorMessage);
      toast(errorMessage, {autoClose: false});
    }
    return response
  };

};

export default new API()
