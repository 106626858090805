import React, { Component } from "react";
import { toast } from "react-toastify";
import API from "../services/api";
import _, { parseInt } from "lodash";
import { prepareAttributes } from "../services/utils";
import { createAttributes } from "../services/utils";

const ATTRIBUTES_MAP = {
  'a_title': 'title',
}

class UpdateProcessing extends Component {
  state = {
    data: [],
    errors: [],
    updatedItems: [],
    columns: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    fulfilURL: "https://bbcicecream.fulfil.io/client/#/model/product.product/",
    alreadyExists: [],
    units: {},
    brands: {},
  };

  async componentDidMount() {
    await this.initData().then(async () => {
      await this.processItems();
    });

    toast("Everything has loaded!");
  }

  convertGramsToPounds = async (weightInGrams) => {
    const gramsPerPound = 0.00220462;
    return weightInGrams * gramsPerPound;
  };

  checkBarcodes = async (ids, newBarcode, barcodeType) => {
    for (let id of ids) {
      const data = await API.getBarcode(id, toast);
      console.log("BARCODE DATA: ", data["code"]);
      console.log("NEW BARCODE: ", newBarcode);
      if (data["code"] !== newBarcode || data['code_type'] !== barcodeType) {
        return true;
      }
    }
    return false;
  };

  updateBarcode = async (product, codes, barcode, type) => {
    for (let id of codes) {
      await API.deleteBarcode(id, toast);
    }
    await API.createBarcode(product, barcode, type, toast);
  };

  processBarcode = async (productData, item) => {
    let barcodeType = 'other'
    if (item["Variant Barcode"]) {
      const valid = await API.validateBarcode(item["Variant Barcode"], toast);
      if ((valid && (!item.hasOwnProperty("Variant Barcode Type")) || item["Variant Barcode Type"] === 'GTIN')) {
        barcodeType = "gtin"
      }
    }

    const productHasBarcode = productData.codes.length > 0;
    if (productHasBarcode && item["Variant Barcode"]) {
      const barcodeRequiresUpdate = await this.checkBarcodes(
        productData["codes"],
        item["Variant Barcode"],
        barcodeType
      );
      console.log("Barcode Requires Update?: ", barcodeRequiresUpdate);
      if (barcodeRequiresUpdate) {
        await this.updateBarcode(
          productData["id"],
          productData["codes"],
          item["Variant Barcode"],
          barcodeType
        );
      }
    } else if (productHasBarcode && !item["Variant Barcode"] && item["Variant Barcode Type"]) {
      console.log("No barcode provided. Assuming barcode type changed", productData["codes"]);
      const barcodeData = await API.getBarcode(productData["codes"][0], toast);
      const barcodeType = item["Variant Barcode Type"] === "GTIN" ? "gtin" : "other"
      if (barcodeData["code_type"] !== barcodeType) {
        await this.updateBarcode(
          productData["id"],
          productData["codes"],
          barcodeData["code"],
          barcodeType
        );
      }
    } else if (!productHasBarcode && item["Variant Barcode"]) {
      console.log("Creating barcode: ", item["Variant Barcode"]);
      await API.createBarcode(
        productData["id"],
        item["Variant Barcode"],
        barcodeType,
        toast
      );
    }
  };

  updateItem = async (item) => {
    let product = await API.getProduct(item["Variant SKU"], toast);
    console.log("PRODUCT: ", product);
    const productExists =
      product.data.message && product.data.message.length > 0;
    if (!productExists) {
      toast(product.data.errorMessage, { autoClose: false });
      console.log(
        "An error has occurred while getting the category: ",
        product.data.errorMessage
      );
      return;
    }
    const productData = product.data.message[0];
    await this.processBarcode(productData, item);

    if (parseFloat(productData.cost_price?.decimal).toFixed(2) !== parseFloat(item['Cost per item']).toFixed(2) && item['Cost per item']) {
      await API.revalueInventory(productData['id'], item["Cost per item"], toast)
    }

    let weightUnit = this.state.units[item["Variant Weight Unit"]] ?? 5
    let dimsUnit = this.state.units[item["Dims UOM"]] ?? 16
    let brandId = this.state.brands[item["Product Vendor"].trim()] ?? false
    if (!brandId) {
      brandId = await API.createProductBrand(item["Product Vendor"].trim(), toast).then(res => res.data.message[0].id ?? false)
      this.setState({ brands: {...this.state.brands, [item["Product Vendor"].trim()]: brandId} })
    }

    const payload = {}

    if (parseFloat(productData.weight) !== parseFloat(item["Variant Weight"]) && item["Variant Weight"]) {
      payload.weight = item["Variant Weight"]
    }

    if (parseFloat(productData.length) !== parseFloat(item["Length"]) && item["Length"]) {
      payload.length = item["Length"]
    }

    if (parseFloat(productData.width) !== parseFloat(item["Width"]) && item["Width"]) {
      payload.width = item["Width"]
    }

    if (parseFloat(productData.height) !== parseFloat(item["Height"]) && item["Height"]) {
      payload.height = item["Height"]
    }

    if (parseInt(productData.weight_uom) !== parseInt(weightUnit)) {
      payload.weight_uom = weightUnit
    }

    if (parseInt(productData.dimensions_uom) !== parseInt(dimsUnit)) {
      payload.dimensions_uom = dimsUnit
    }

    if (parseInt(productData.brand) !== parseInt(brandId) && brandId) {
      payload.brand = brandId
    }

    if (productData.variant_name !== item["Title"] && item["Title"]) {
      payload.variant_name = item["Title"]
    }

    if (parseFloat(productData.list_price.decimal).toFixed(2) !== parseFloat(item["Variant Price"]).toFixed(2) && item["Variant Price"]) {
      payload.list_price = {
        __class__: "Decimal",
        decimal: item["Variant Price"]
      }
    }

    if (Object.values(payload).length > 0) {
      await API.updateProduct(productData["id"], payload, toast);
    }
    await this.updateAttributes(item, productData)

    const { updatedItems } = this.state;
    updatedItems.push(productData);
    this.setState({ updatedItems });
  };

  timeout = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  processItems = async () => {
    this.props.setLoading(true)
    const { data } = this.state;
    let count = 0;
    for (const item of data) {
      count++;
      if (count >= 3) {
        count = 0;
        // console.log("Timeout: 5000ms");
        // await this.timeout(5000);
        await this.updateItem(item);
      } else {
        await this.updateItem(item);
      }
    }
    this.props.setLoading(false)
  };

  getProductAttributeJson = (productData, attributeName) => {
    for (const attribute of productData.attributes_json) {
      if (attribute.attribute_name.toLowerCase() === attributeName) {
        return attribute
      }
    }

    return false
  }

  updateAttributes = async (item, productData) => {
    let attributesToUpdate = []
    let attributesToCreate = []
    prepareAttributes(item).map(attribute => {
      if (ATTRIBUTES_MAP.hasOwnProperty(attribute.attribute)) {
        attribute.attribute = ATTRIBUTES_MAP[attribute.attribute]
      }

      const attributeJson = this.getProductAttributeJson(productData, attribute.attribute)
      if (attributeJson) {
        if (attributeJson.value !== attribute.name) {
          attributesToUpdate.push(attribute)
        }
      } else {
        attributesToCreate.push(attribute)
      }

      return attribute
    })

    await createAttributes(attributesToCreate, productData["id"], toast)
    await createAttributes(attributesToUpdate, productData["id"], toast)
  }

  initData = async () => {
    this.setState({ data: this.props.data });
    await API.getUnits().then((response) => {
      this.setState({ 
        units: response.data.message.reduce((previous, current) => {
          return {...previous, [current.symbol]: current.id}
        }, {})
      });
    })
    await API.getProductBrands().then((response) => {
      this.setState({ 
        brands: response.data.message.reduce((previous, current) => {
          return {...previous, [current.name]: current.id}
        }, {})
      });
    })
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGenre,
      searchQuery,
      data,
    } = this.state;

    return { totalCount: data.length, data };
  };

  handleSubmit = () => {
    const step = {
      id: 1,
      name: "Upload CSV",
    };
    const data = [];
    this.props.updateData(data);
    this.props.updateStep(step);
  };

  render() {
    const { data, updatedItems, alreadyExists, fulfilURL } = this.state;

    return (
      <React.Fragment>
        <div className="row justify-content-center pb-3">
          <span>
            Updated {updatedItems.length} out of {data.length}{" "}
          </span>
          {updatedItems.length > 0 && (
            <div>
              <ul>
                {updatedItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={fulfilURL + item["id"]} target="_blank">
                        {item["rec_name"]}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <hr />
        <div className="row justify-content-center">
          {alreadyExists.length > 0 && (
            <div>
              <span>{alreadyExists.length} already exist in Fulfil.</span>
              <ul>
                {alreadyExists.map((product, index) => {
                  return <li key={index}>{product}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateProcessing;
